import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export function useAuthFetch() {
    const { getAccessTokenSilently } = useAuth0();

    const authFetch = useCallback(async (url, options = {}) => {
        const accessToken = await getAccessTokenSilently();
        const headers = {
            ...options.headers,
            Authorization: `Bearer ${accessToken}`,
        };
        return fetch(url, { ...options, headers });
    }, [getAccessTokenSilently]); // getAccessTokenSilently est supposé être stable

    return authFetch;
}

