import React, { useEffect, useState } from 'react';
import Button from '../../atoms/Button/Button';
import TextInput from "../../atoms/TextInput/TextInput";
import './TemplateForm.css';

const TemplateForm = ({ onSubmit, initialData = {} }) => {
    const [name, setName] = useState(initialData.name || '');
    const [content, setContent] = useState(initialData.content || '');

    useEffect(() => {
        if (initialData.name) {
            setName(initialData.name);
        }
        if (initialData.content) {
            setContent(initialData.content);
        }
    }, [initialData.name, initialData.content]);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleHtmlContentChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setContent(e.target.result);
            };
            reader.readAsText(file);
        }
    };

    const handleDownload = (e) => {
        e.preventDefault(); // Prévenir toute action par défaut, comme la soumission d'un formulaire
        const blob = new Blob([content], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        document.body.appendChild(tempLink);
        tempLink.href = url;
        tempLink.download = `${name}.html`;
        tempLink.click();
        document.body.removeChild(tempLink);
        URL.revokeObjectURL(url);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, content });
    };

    return (
        <form onSubmit={handleSubmit} className="template-form">
            <div className="template-form-group">
                <label htmlFor="templateName">Nom du Template</label>
                <TextInput id="templateName" value={name} onChange={handleNameChange} />
            </div>
            <div className="template-form-group">
                <label htmlFor="templateHtml">Contenu HTML</label>
                <input type="file" id="templateHtml" onChange={handleHtmlContentChange} accept=".html" />
            </div>
            <div id="nav-button">
                <Button type="submit" className="submit-button">Soumettre</Button>
                <Button type="button" onClick={handleDownload} className="download-button">Télécharger HTML</Button>
            </div>
        </form>
    );
};

export default TemplateForm;
