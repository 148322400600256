import React, { useState } from 'react';
import Button from "../../atoms/Button/Button";
import TextInput from "../../atoms/TextInput/TextInput";
import './EmailPopup.css';

const EmailPopup = ({ onClose, onSend }) => {
    const [emails, setEmails] = useState(['']);

    const handleAddEmail = () => {
        setEmails([...emails, '']);
    };

    const handleChangeEmail = (index, value) => {
        const newEmails = [...emails];
        newEmails[index] = value;
        setEmails(newEmails);
    };

    const handleSend = async () => {
        onSend(emails);
    };

    return (
        <div className="email-popup">
            <div className="email-popup-content">
                <button onClick={onClose} className="close-button">&times;</button>
                <h2>Envoyer BAT</h2>
                <div className="email-fields-container">
                    {emails.map((email, index) => (
                        <div key={index} className="email-field">
                            <label className="email-label">Email:</label>
                            <TextInput
                                value={email}
                                onChange={(e) => handleChangeEmail(index, e.target.value)}
                            />
                        </div>
                    ))}
                </div>
                <div className="buttons-container">
                    <Button onClick={handleAddEmail}>Ajouter un email</Button>
                    <Button onClick={handleSend}>Envoi</Button>
                </div>
            </div>
        </div>
    );

};

export default EmailPopup;
