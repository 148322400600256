import React, { useEffect, useState } from 'react';
import CampaignForm from '../../../components/organisms/CampaignForm/CampaignForm';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch"; // Nouvelle importation

const EditCampaign = () => {
    const navigate = useNavigate();
    const [campaignName, setCampaignName] = useState('');
    const [theoricSendDate, setTheoricSendDate] = useState('');
    const { campaignId } = useParams();
    const { triggerNotification } = useNotification();
    const authFetch = useAuthFetch(); // Nouvelle constante

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/campaign/fetch/${campaignId}`); // Remplacement de fetch par authFetch
                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }
                const campaign = await response.json();
                setCampaignName(campaign.name);
                setTheoricSendDate(campaign.theoric_scheduled_date);
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: 'Erreur lors de la récupération des détails de la campagne.',
                    duration: 3000,
                    persistent: false
                });
            }
        };

        fetchCampaign();
    }, [campaignId, triggerNotification, authFetch]);

    const handleFormSubmit = async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/campaign/update/${campaignId}`, { // Remplacement de fetch par authFetch
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: campaignName, theoric_scheduled_date: theoricSendDate }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de l’ajout de la campagne');
            }

            const data = await response.json();
            triggerNotification({
                type: 'success',
                content: data.message || 'La campagne a été modifiée avec succès.',
                duration: 3000,
                persistent: false
            });
            setTimeout(() => {
                navigate(`/campagnes`);
            }, 800);

        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: false
            });
        }
    };


    return (
        <div>
            <Breadcrumb />
            <h1>Modifier une Campagne</h1> {/* Correction du titre */}
            <CampaignForm
                campaignName={campaignName}
                setCampaignName={setCampaignName}
                theoricSendDate={theoricSendDate}
                setTheoricSendDate={setTheoricSendDate}
                onSubmit={handleFormSubmit}
            />
        </div>
    );
};

export default EditCampaign;
