import React from 'react';
import Button from '../../atoms/Button/Button';
import './BatchForm.css';
import DatepickerTime from "../../atoms/DatePickerTime/DatepickerTime";
import {convertISO8601ToYYYYMMDDHMS} from "../../../utils/dateUtils";
import TextInput from "../../atoms/TextInput/TextInput";

const BatchForm = ({
                       sendDate,
                       setSendDate,
                       name,
                       setName,
                       onSubmit,
}) => {


    const handleSubmit = (event) => {
        event.preventDefault();

        const batchData = { name: name,scheduled_date: convertISO8601ToYYYYMMDDHMS(sendDate)};
        onSubmit(batchData);
    };

    return (
        <div className="batch-form-container">
            <form className="batch-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Nom du batch</label>
                    <TextInput
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Nom du batch"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="sendDate">Date et heure d'envoi</label>
                    <DatepickerTime
                        id="sendDate"
                        value={sendDate}
                        onChange={(e) => setSendDate(e.target.value)}
                    />
                </div>
                <Button type="submit">Enregistrer</Button>
            </form>
        </div>
    );
};

export default BatchForm;
