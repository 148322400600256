import React from 'react';
import './CredentialsList.css';

const CredentialsList = ({ credentials }) => {

    if (!credentials || credentials.length === 0) {
        return <p>Aucun credentials disponible pour le moment.</p>;
    }


    return (
        <ul className="credentials-list">
            {credentials.map((credential) => (
                <li key={credential.uuid} className="credentials-item">
                    <div>
                        <div>
                            <p><b>email</b>: {credential.email} - <b>Login</b>: {credential.login}</p>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default CredentialsList;
