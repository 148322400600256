import React, { useState, useEffect } from 'react';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import Button from "../../../components/atoms/Button/Button";
import './Countries.css';
import Loader from "../../../components/atoms/Loader/Loader";
import HelpPopUp from "../../../components/organisms/HelpPopUp/HelpPopUp";
import { useNotification } from "../../../NotificationContext";
import {useAuthFetch} from "../../../hooks/useAuthFetch";

const Countries = () => {
    const { triggerNotification } = useNotification();
    const [data, setData] = useState({ formulas_codes: [], languages_codes: {}, countries_codes: {} });
    const [isLoading, setIsLoading] = useState(false);
    const authFetch = useAuthFetch();

    const formatExamples = [
        { format: "MilSpaceDecCommaCurRightSpace", examples: ["X XXX,YY €", "X XXX,YY EUR"] },
        { format: "MilDotDecCommaCurRightSpace", examples: ["X.XXX,YY €", "X.XXX,YY EUR"] },
        { format: "MilDotDecCommaCurRight", examples: ["X.XXX,YY€"] },
        { format: "MilCommaDecDotCurRight", examples: ["X,XXX.YY€"] },
        { format: "CurLeftSpaceMilDotDecComma", examples: ["€ X.XXX,YY", "EUR X.XXX,YY"] },
        { format: "CurLeftSpaceMilCommaDecDot", examples: ["€ X,XXX.YY", "EUR X,XXX.YY"] },
        { format: "CurLeftMilSpaceDecComma", examples: ["€X XXX,YY", "EUR X XXX,YY"] },
        { format: "CurLeftMilDotDecComma", examples: ["€X.XXX,YY"] },
        { format: "CurLeftMilCommaDecDot", examples: ["€X,XXX.YY"] },
        { format: "MilSpaceDecCommaCurRight", examples: ["X XXX,YY€"] }
    ];

    const helpInstructions = [
        "Cliquez sur 'Télécharger les codes autorisés' pour obtenir un fichier Excel.",
        "Référez-vous aux Formats Numériques pour savoir si une langue a le bon format",
    ];

    useEffect(() => {
        const fetchConfigData = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/utility/files-configurations/countries`);

                if (!response.ok) throw new Error("Erreur lors de la récupération de la configuration");

                const configData = await response.json();
                setData(configData);
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message,
                    duration: 3000,
                    persistent: false
                });
                setIsLoading(false);
            }
        };
        fetchConfigData();
    }, [triggerNotification, authFetch]);


    const downloadExcel = async () => {
        setIsLoading(true);
        try {
            const response = await authFetch(`${config.API_URL}/emailing/codelang/spreadsheet`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error("Erreur lors du téléchargement des codes autorisés");
            }

            const data = await response.json();
            const encodedContent = data.file;
            const filename = data.filename || 'codes.xlsx';

            const link = document.createElement('a');
            link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${encodedContent}`;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Erreur lors du téléchargement :', error);
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <div>
            <HelpPopUp instructions={helpInstructions} />
            {isLoading && <Loader />}
            <Breadcrumb />
            <h1>Visualiser les codes pays, formules et langues</h1>
            <Button onClick={downloadExcel}>Télécharger les codes autorisés</Button>
            <div className="table-container">
                <h2>Formules</h2>
                <table className="data-table">
                    <tbody>
                    {data.formulas_codes.map((formula, index) => (
                        <tr key={index}>
                            <td>{formula}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <h2>Pays</h2>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th>ISO Pays</th>
                        <th>Nom Pays</th>
                        <th>ISO Currency</th>
                        <th>Sigle Currency</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(data.countries_codes).map(([code, { name, currency_info }], index) => (
                        <tr key={index}>
                            <td>{code}</td>
                            <td>{name}</td>
                            <td>{currency_info.iso}</td>
                            <td>{currency_info.sigle}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>


                <h2>Formats Numériques</h2>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th>Format</th>
                        <th>Exemples</th>
                    </tr>
                    </thead>
                    <tbody>
                    {formatExamples.map((item, index) => (
                        <tr key={index}>
                            <td>{item.format}</td>
                            <td>{item.examples.join(" / ")}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <h2>Langues</h2>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th>ISO</th>
                        <th>Nom</th>
                        <th>Format ISO</th>
                        <th>Format Sigle</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(data.languages_codes).map(([code, details], index) => (
                        <tr key={index}>
                            <td>{code}</td>
                            <td>{details.name}</td>
                            <td>{details.format_iso}</td>
                            <td>{details.format_sigle}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Countries;
