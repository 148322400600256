import React from 'react';
import './Button.css';

const Button = ({ children, onClick, disabled, status, className }) => {
    const buttonClass = `button button-${status} ${className || ''}`;
    return (
        <button className={buttonClass} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;
