import React from 'react';
import './TemplatesList.css';
import Button from "../../atoms/Button/Button";

const TemplateList = ({ templates, handleNavigateToEditTemplate }) => {
    if (!templates || templates.length === 0) {
        return <p>Aucun template disponible pour le moment.</p>;
    }

    return (
        <ul className="template-list">
            {templates.map((template, index) => (
                <li key={index} className="template-item">
                    <p className="template-name"><strong>{template.name}</strong>:</p>
                    <p className="template-uuid"><strong>{template.uuid}</strong></p>
                    <iframe className="template-preview" srcDoc={template.content} title={template.name}></iframe>
                    <Button onClick={() => handleNavigateToEditTemplate(template.uuid)}>Éditer</Button>
                </li>
            ))}
        </ul>
    );
};

export default TemplateList;
