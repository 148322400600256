import React from 'react';
import './ToggleButton.css';

const ToggleButton = ({ label, value, onChange }) => {
    return (
        <div className="toggle-button">
            <label>{label}</label>
            <button
                className={value ? 'toggle-on' : 'toggle-off'}
                onClick={() => onChange(!value)}
            >
                {value ? 'ON' : 'OFF'}
            </button>
        </div>
    );
};

export default ToggleButton;
