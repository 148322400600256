import React from 'react';
import TemplateForm from '../../../components/organisms/TemplateForm/TemplateForm';
import { useNavigate, useParams } from 'react-router-dom';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch"; // Nouvelle importation

const AddTemplate = () => {
    const navigate = useNavigate();
    const { campaignId } = useParams();
    const { triggerNotification } = useNotification();
    const authFetch = useAuthFetch(); // Nouvelle constante

    const handleSubmit = async (templateData) => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/template/add`, { // Remplacement de fetch par authFetch
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...templateData,
                    campaign_uuid: campaignId
                }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Erreur lors de l’ajout du template');
            }

            triggerNotification({
                type: 'success',
                content: 'Template ajouté avec succès.',
                duration: 3000,
                persistent: false
            });
            setTimeout(() => navigate(`/campagnes/${campaignId}`), 800);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        }
    };

    return (
        <div>
            <Breadcrumb />
            <h1>Ajouter un Template</h1>
            <TemplateForm
                onSubmit={handleSubmit} />
        </div>
    );
};

export default AddTemplate;
