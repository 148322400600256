import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import config from "../../../config";
import Button from "../../../components/atoms/Button/Button";
import EligibleFields from "../../../components/molecules/EligibleField/EligibleField";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import Loader from "../../../components/atoms/Loader/Loader";
import HelpPopUp from "../../../components/organisms/HelpPopUp/HelpPopUp";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch"; // Nouvelle importation

const AddEligible = () => {
    const { triggerNotification } = useNotification();
    const navigate = useNavigate();
    const { campaignId, batchId } = useParams();
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [eligibles, setEligibles] = useState([]);
    const [codeLangOptions, setCodeLangOptions] = useState([]);
    const authFetch = useAuthFetch(); // Nouvelle constante

    const helpInstructions = [
        "Excel: Les colonnes FirstName et Lastname sont obligatoires, même si les noms sont vides",
        "L'ordre est important. Firstname en A1, Lastname en B1, Email en C1, Code en D1",
        "La casse est importante. Respectez la majuscule en début de mot",
    ]

    useEffect(() => {
        const fetchCodeLangs = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/codelang/list?campaign_uuid=${campaignId}`); // Remplacement de fetch par authFetch
                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }
                const data = await response.json();
                const options = data.map(cl => ({ label: cl.code_lang, value: cl.code_lang }));
                setCodeLangOptions(options);

                // Définir la valeur par défaut pour le premier éligible si aucuns éligibles n'ont été définis
                if (options.length > 0 && eligibles.length === 0) {
                    setEligibles([{ first_name: '', last_name: '', email: '', code_lang: options[0].value }]);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des codeLangs:", error);
            }
        };

        fetchCodeLangs();
    }, [campaignId, eligibles.length, authFetch]); // Ajout de authFetch dans les dépendances

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        return regex.test(email);
    };

    const cleanEmail = (email) => {
        // eslint-disable-next-line no-control-regex
        return email.replace(/[\s\x00-\x1F\x7F-\x9F]+/g, "").trim();
    };

    const handleUpload = async () => {
        setEligibles([]);

        if (!file) {
            triggerNotification({
                type: 'error',
                content: 'Veuillez sélectionner un fichier Excel.',
                duration: 3000,
                persistent: false
            });
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const requiredHeaders = ["Firstname", "Lastname", "Email", "Code"];
            const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
            const missingHeaders = requiredHeaders.filter(h => !headers.includes(h));
            if (missingHeaders.length > 0) {
                triggerNotification({
                    type: 'error',
                    content: `Les entêtes suivantes sont manquantes dans le fichier Excel : ${missingHeaders.join(", ")}`,
                    duration: 3000,
                    persistent: true
                });
                setIsLoading(false);
                return;
            }

            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            const formattedData = jsonData.map(row => ({
                first_name: row['Firstname'] || '',
                last_name: row['Lastname'] || '',
                email: cleanEmail(row['Email'].trim()) || '',
                code_lang: row['Code'] || ''
            }));

            // Collecter les erreurs
            const invalidEmails = [];
            const invalidCodes = [];
            const duplicateCombinations = [];
            const uniqueCombinations = new Set();
            formattedData.forEach((row, index) => {
                if (row.email && !isValidEmail(row.email)) {
                    invalidEmails.push(`Ligne ${index + 1}: ${row.email}`);
                }
                console.log()
                if (row.code_lang && !codeLangOptions.some(cl => cl.value === row.code_lang)) {
                    invalidCodes.push(`Ligne ${index + 1}: ${row.code_lang}`);
                }
                const combo = `${row.email}-${row.code_lang}`;
                if (uniqueCombinations.has(combo)) {
                    duplicateCombinations.push(`Ligne ${index + 1} - Email: ${row.email}`);
                } else {
                    uniqueCombinations.add(combo);
                }
            });

            // Construire le message d'erreur
            let errorMessage = '';
            if (invalidEmails.length > 0) {
                errorMessage += `Emails invalides:\n${invalidEmails.join('\n')}\n\n`;
            }
            if (invalidCodes.length > 0) {
                errorMessage += `Codes langues invalides:\n${invalidCodes.join('\n')}\n\n`;
            }
            if (duplicateCombinations.length > 0) {
                errorMessage += `Combinaisons email/code langue dupliquées:\n${duplicateCombinations.join('\n')}\n\n`;
            }

            if (errorMessage) {
                triggerNotification({
                    type: 'error',
                    content: `Erreurs détectées :\n${errorMessage}`,
                    duration: 3000,
                    persistent: true
                });
                setIsLoading(false);
                return;
            }

            const chunkSize = 20000;
            let errorOccurred = false;

            for (let i = 0; i < formattedData.length; i += chunkSize) {
                if (errorOccurred) break;

                const chunk = formattedData.slice(i, i + chunkSize);
                try {
                    await sendDataToApi(chunk);
                } catch (error) {
                    console.error("Erreur lors de l'envoi d'un batch:", error);
                    errorOccurred = true;
                    triggerNotification({
                        type: 'error',
                        content: error.message || 'Erreur lors de l’envoi des données.',
                        duration: 3000,
                        persistent: false
                    });
                    setIsLoading(false);
                }
            }

            if (!errorOccurred) {
                triggerNotification({
                    type: 'success',
                    content: 'Éligibles ajoutés avec succès',
                    duration: 800,
                    persistent: false
                });
                setIsLoading(false);
                setTimeout(() => {
                    navigate(`/campagnes/${campaignId}/batch/${batchId}`);
                }, 800);
            }
            setIsLoading(false);
        };

        reader.readAsBinaryString(file);
    };

    const sendDataToApi = async (data) => {
        data = data.map(eligible => ({ ...eligible, batch_uuid: batchId }));

        try {
            const response = await authFetch(`${config.API_URL}/emailing/eligible/add`, { // Remplacement de fetch par authFetch
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                const responseData = await response.json();
                const errorMessage = responseData.error || 'Erreur lors de l’envoi des données.';
                triggerNotification({
                    type: 'error',
                    content: errorMessage,
                    duration: 3000,
                    persistent: false
                });
                setIsLoading(false);
                throw new Error(errorMessage);
            }
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: false
            });
            setIsLoading(false);
            throw error;
        }
    };

    const addEligibleField = () => {
        const defaultCodeLang = codeLangOptions.length > 0 ? codeLangOptions[0].value : '';
        setEligibles([...eligibles, { first_name: '', last_name: '', email: '', code_lang: defaultCodeLang }]);
    };

    const updateEligibleField = (index, field, value) => {
        const updatedEligibles = eligibles.map((eligible, i) => {
            if (i === index) {
                return { ...eligible, [field]: value };
            }
            return eligible;
        });
        setEligibles(updatedEligibles);
    };

    const deleteEligibleField = (index) => {
        setEligibles(eligibles.filter((_, i) => i !== index));
    };

    const handleSubmitForm = () => {
        if (eligibles.length === 0) {
            triggerNotification({
                type: 'error',
                content: 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: false
            });
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        sendDataToApi(eligibles).then(() => {
            triggerNotification({
                type: 'success',
                content: 'Éligibles ajoutés avec succès',
                duration: 800,
                persistent: false
            });
            setIsLoading(false);
            setTimeout(() => {
                navigate(`/campagnes/${campaignId}/batch/${batchId}`);
            }, 800);
        }).catch((error) => {
            console.error("Erreur lors de l'envoi des éligibles:", error);
            setIsLoading(false);
        });
    };

    return (
        <div>
            {isLoading && <Loader />}
            <HelpPopUp instructions={helpInstructions} />

            <Breadcrumb />
            <h1>Ajouter des Éligibles</h1>
            <h2>Importez un fichier Excel</h2>
            <p>Préparez un fichier Excel avec les colonnes "Firstname", "Lastname", "Email", et "Code".</p>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            <Button onClick={handleUpload} disabled={!file || isLoading}>{isLoading ? 'Chargement...' : 'Envoyer le fichier'}</Button>
            <h2>Ou remplissez les champs</h2>
            <div id="eligible-fields">
                {eligibles.map((eligible, index) => (
                    <EligibleFields
                        key={index}
                        index={index}
                        eligible={eligible}
                        onEligibleChange={updateEligibleField}
                        onDelete={deleteEligibleField}
                        codeLangOptions={codeLangOptions}
                    />
                ))}
            </div>
            <div className="validation">
                <Button onClick={addEligibleField} disabled={codeLangOptions.length === 0}>Ajouter un éligible</Button>
                <Button onClick={handleSubmitForm}>Envoyer les éligibles</Button>
            </div>
        </div>
    );
};

export default AddEligible;
