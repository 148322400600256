import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BatchList from '../../../components/organisms/BatchList/BatchList';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch"; // Nouvelle importation

const Batchs = () => {
    const { campaignId } = useParams();
    const [batchs, setBatchs] = useState([]);
    const { triggerNotification } = useNotification();
    const authFetch = useAuthFetch(); // Nouvelle constante

    useEffect(() => {
        const fetchBatchs = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/batch/list?campaign_uuid=${campaignId}`); // Remplacement de fetch par authFetch
                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }
                const data = await response.json();
                setBatchs(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des campagnes:", error);
            }
        };

        fetchBatchs();
    }, [campaignId, authFetch]);

    const handleToggleBatchStatus = async (batchId, currentStatus) => {
        const newStatus = currentStatus === 'ready' ? 'created' : 'ready';

        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/status/update/${batchId}`, { // Remplacement de fetch par authFetch
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la mise à jour du statut du batch');
            }

            setBatchs(batchs.map(batch => batch.uuid === batchId ? { ...batch, status: newStatus } : batch));
            triggerNotification({
                type: 'success',
                content: 'Statut du batch mis à jour avec succès.',
                duration: 3000,
                persistent: false
            });
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut du batch:", error);
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        }
    };

    return (
        <div>
            <Breadcrumb />
            <h1>Batch de la campagne {campaignId}</h1>

            <BatchList
                campaignId={campaignId}
                batchs={batchs}
                onToggleStatus={handleToggleBatchStatus}
            />
        </div>
    );
};

export default Batchs;
