import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = ({ permissions }) => {
    const hasEmailingPermission = permissions.includes('read:emailing');
    const hasEmailingDashboardPermission = permissions.includes('read:emailing:dashboard');

    return (
        <div className="sidebar">
            <div className="menu-container">
                {hasEmailingDashboardPermission && (
                    <Link to="/">Dashboard</Link>
                )}
                {hasEmailingPermission && (
                    <>
                        <Link to="/campagnes">Campagnes Emailing</Link>
                        <Link to="/prices">Prix & Taux</Link>
                        <Link to="/countries">Formules & Pays</Link>
                        <Link to="/credentials">Logins Banques</Link>
                        <Link to="/logs">Logs</Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
