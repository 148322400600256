import React from 'react';
import './BatchList.css';
import StatusAtom from "../../atoms/Status/Status";
import Button from "../../atoms/Button/Button";
import {useNavigate} from 'react-router-dom';
import { convertToDDMMYYYYWithTime } from '../../../utils/dateUtils';
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";

const BatchList = ({ campaignId, batchs, onToggleStatus }) => {
    const navigate = useNavigate();

    if (!batchs || batchs.length === 0) {
        return <p className="alert-message">Aucun batch disponible pour le moment.</p>;
    }



    const handleShowbatchClick = (batchId) => {
        navigate(`/campagnes/${campaignId}/batch/${batchId}`);
    };


    return (
        <ul className="batch-list">
            {batchs.map((batch) => (
                <li key={batch.uuid} className="batch-item">

                    <div>
                        <StatusAtom status={batch.status} />
                        <div>
                            <p><strong>Date d'envoi:</strong> {convertToDDMMYYYYWithTime(batch.scheduled_date)}</p>
                            <p><strong>Créée le:</strong> {convertToDDMMYYYYWithTime(batch.create_date)}</p>
                        </div>
                    </div>
                    <div>
                        <p>
                            {batch.name}
                        </p>
                    </div>

                    <div>
                        <Button onClick={() => handleShowbatchClick(batch.uuid)}>Voir le batch</Button>
                    </div>
                    <div>
                        <p>Total éligibles: (<strong>{batch.count_eligibles}</strong>)</p>
                    </div>
                    {(batch.status === 'ready' || batch.status === 'created') &&
                    <ToggleButton
                        label={'Prêt pour l\'envoi ?'}
                        value={batch.status === 'ready'}
                        onChange={() => onToggleStatus(batch.uuid, batch.status)}
                    />
                    }
                </li>
            ))}
        </ul>
    );
};

export default BatchList;
