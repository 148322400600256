import React from 'react';
import TextInput from "../../atoms/TextInput/TextInput";
import Select from "../../atoms/Select/Select";
import Button from "../../atoms/Button/Button";
import './EligibleField.css';

const EligibleFields = ({ index, eligible, onEligibleChange, onDelete, codeLangOptions }) => (
    <div className="eligible-field">
        <div className="form-group">
            <TextInput
                value={eligible.firstName}
                onChange={e => onEligibleChange(index, 'first_name', e.target.value)}
                placeholder="First Name"
            />
            <TextInput
                value={eligible.lastName}
                onChange={e => onEligibleChange(index, 'last_name', e.target.value)}
                placeholder="Last Name"
            />
            <TextInput
                value={eligible.email}
                onChange={e => onEligibleChange(index, 'email', e.target.value)}
                placeholder="Email"
            />
            <Select
                value={eligible.codeLang}
                onChange={e => onEligibleChange(index, 'code_lang', e.target.value)}
                options={codeLangOptions}
            />

            <Button onClick={() => onDelete(index)}>Supprimer</Button>
        </div>
    </div>
);

export default EligibleFields;
