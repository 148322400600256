import React from 'react';
import './Checkbox.css';

const Checkbox = ({ checked, onChange, label, ...props }) => {
    return (
        <label className="custom-checkbox">
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                {...props}
            />
            <span className="checkbox-indicator"></span>
            {label && <span className="checkbox-label">{label}</span>}
        </label>
    );
};

export default Checkbox;
