// src/components/organisms/CampaignList.js
import React from 'react';
import './CampaignList.css';
import StatusAtom from "../../atoms/Status/Status";
import Button from "../../atoms/Button/Button";
import { useNavigate } from 'react-router-dom';
import { convertToDDMMYYYY, convertToDDMMYYYYWithTime } from '../../../utils/dateUtils';

const CampaignList = ({ campaigns }) => {

    const navigate = useNavigate();

    if (!campaigns || campaigns.length === 0) {
        return <p>Aucune campagne disponible pour le moment.</p>;
    }



    const handleShowCampaignClick = (campaignId) => {
        navigate(`/campagnes/${campaignId}`);
    };


    return (
        <ul className="campaign-list">
            {campaigns.map((campaign) => (
                <li key={campaign.uuid} className="campaign-item">
                    <div>
                        <StatusAtom status={campaign.status} />
                        <div>
                            <p><strong>Nom:</strong> {campaign.name}</p>
                            <p><strong>Date théorique d'envoi:</strong> {convertToDDMMYYYY(campaign.theoric_scheduled_date)}</p>
                            <p><strong>Créée le:</strong> {convertToDDMMYYYYWithTime(campaign.create_date)}</p>
                        </div>
                    </div>
                    <div>
                        <Button onClick={() => handleShowCampaignClick(campaign.uuid)}>Voir la campagne</Button>
                    </div>

                </li>
            ))}
        </ul>
    );
};

export default CampaignList;
