import React, { useCallback } from 'react'; // Importez useCallback
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [userMetadata, setUserMetadata] = React.useState(null);

    // Utilisez useCallback pour mémoriser votre fonction
    const fetchAndSetUserMetadata = useCallback(async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            // Ici, vous incluriez votre logique pour extraire les rôles et les scopes
            const roles = user['http://monapp.com/roles'] || []; // Exemple de structure
            const scopes = accessToken.scope ? accessToken.scope.split(' ') : []; // Gestion des scopes

            setUserMetadata({ roles, scopes });
        } catch (e) {
            console.error(e);
        }
    }, [getAccessTokenSilently, user]); // Incluez ici toutes les dépendances externes de la fonction

    React.useEffect(() => {
        fetchAndSetUserMetadata();
    }, [fetchAndSetUserMetadata]); // Maintenant, vous pouvez inclure fetchAndSetUserMetadata comme dépendance

    if (!isAuthenticated) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>Profile</h2>
            {user && (
                <div>
                    <img src={user.picture} alt={user.name} />
                    <h3>{user.name}</h3>
                    <p>Email: {user.email}</p>
                    {userMetadata && (
                        <div>
                            <p>Roles:</p>
                            <pre>{JSON.stringify(userMetadata.roles, null, 2)}</pre>
                            <p>Scopes:</p>
                            <pre>{JSON.stringify(userMetadata.scopes, null, 2)}</pre>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Profile;
