import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from "../../atoms/Loading/Loading";
// import {jwtDecode} from "jwt-decode";

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                // const accessToken = await getAccessTokenSilently();
                // console.log("Access Token:", accessToken);

                // // Décoder le token pour extraire les scopes
                // const decodedToken = jwtDecode(accessToken);
                // console.log("Decoded Token:", decodedToken);
                //
                // // Afficher les scopes en console
                // if (decodedToken.scope) {
                //     console.log("Scopes:", decodedToken.scope.split(' '));
                // }
            } catch (e) {
                console.error("Error getting access token:", e);
            }
        };

        if (isAuthenticated) {
            fetchAccessToken();
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    if (isLoading) {
        return <Loading />;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
