import React from 'react';
import './Status.css';

const getStatusClassName = (status) => {
    return `status status-${status}`;
};

const StatusAtom = ({ status }) => {
    const statusClassName = getStatusClassName(status);
    return (
        <div className={statusClassName}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
        </div>
    );
};

export default StatusAtom;
