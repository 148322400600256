import React, { useState } from 'react';
import BatchForm from '../../../components/organisms/BatchForm/BatchForm';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useParams, useNavigate } from "react-router-dom";
import { convertISO8601ToYYYYMMDDHMS } from "../../../utils/dateUtils";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch";

const AddBatch = () => {
    const { campaignId } = useParams();
    const navigate = useNavigate();
    const authFetch = useAuthFetch(); // Initialisation de authFetch
    const [sendDate, setSendDate] = useState('');
    const [name, setName] = useState('');
    const { triggerNotification } = useNotification();

    const handleFormSubmit = async () => {

        const batchData = {
            campaign_uuid: campaignId,
            scheduled_date: convertISO8601ToYYYYMMDDHMS(sendDate),
            name: name,
        };

        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/add`, { // Remplacement de fetch par authFetch
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(batchData),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de l’ajout du batch');
            }

            const data = await response.json();

            triggerNotification({
                type: 'success',
                content: data.message || 'Le batch a été ajoutée avec succès.',
                duration: 3000,
                persistent: false
            });
            setTimeout(() => {
                navigate(`/campagnes/${campaignId}`);
            }, 800);


        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: false
            });
        }
    };


    return (
        <div>
            <Breadcrumb />
            <h1>Ajouter un batch</h1>
            <BatchForm
                isEdit={false}
                sendDate={sendDate}
                setSendDate={setSendDate}
                name={name}
                setName={setName}
                onSubmit={handleFormSubmit}
            />

        </div>
    );
};

export default AddBatch;
