import React, {useState, useEffect, useCallback} from 'react';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import Button from "../../../components/atoms/Button/Button";
import Pagination from "../../../components/molecules/Pagination/Pagination";
import * as XLSX from 'xlsx';
import Loader from "../../../components/atoms/Loader/Loader";
import TextInput from "../../../components/atoms/TextInput/TextInput";
import CredentialsList from "../../../components/organisms/CredentialsList/CredentialsList";
import './Credentials.css';
import HelpPopUp from "../../../components/organisms/HelpPopUp/HelpPopUp";
import {useNotification} from "../../../NotificationContext";
import {useAuthFetch} from "../../../hooks/useAuthFetch";

const Credentials = () => {
    const { triggerNotification } = useNotification();
    const [credentials, setCredentials] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [emailFilter, setEmailFilter] = useState('');
    const CHUNK_SIZE = 20000;
    const authFetch = useAuthFetch();


    const helpInstructions = [
        "Format attendu du fichier Excel: Colonne A: Email, Colonne B: Login"
    ];

    const fetchCredentials = useCallback(async () => {
        setIsLoading(true);
        try {
            let queryParam = `?page=${currentPage}`;
            if (emailFilter) queryParam += `&email=${encodeURIComponent(emailFilter)}`;
            const response = await authFetch(
                `${config.API_URL}/emailing/credentials/list${queryParam}`,
            );
            if (!response.ok) throw new Error("Erreur lors de la récupération des identifiants");
            const data = await response.json();
            setCredentials(data.credentials);
            setTotalPages(data.totalPages);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, emailFilter, triggerNotification, authFetch]);

    useEffect(() => {
        fetchCredentials();
    }, [fetchCredentials]);

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const cleanEmail = (email) => {
        // eslint-disable-next-line no-control-regex
        return email.replace(/[\s\x00-\x1F\x7F-\x9F]+/g, "").trim();
    };


    const handleCredentialsUpload = async (file) => {
        if (!file) {
            triggerNotification({
                type: 'error',
                content: 'Aucun fichier sélectionné. Veuillez choisir un fichier',
                duration: 3000,
                persistent: false
            });
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

            const headers = jsonData[0];
            const requiredHeaders = ["Email", "Login"];
            const missingHeaders = requiredHeaders.filter(h => !headers.includes(h));
            if (missingHeaders.length > 0) {
                triggerNotification({
                    type: 'error',
                    content: `Les en-têtes suivantes sont manquantes dans le fichier Excel : ${missingHeaders.join(", ")}`,
                    duration: 3000,
                    persistent: false
                });
                setIsLoading(false);
                return;
            }

            let emailSet = new Set();
            let duplicates = new Set();
            let invalidEmails = [];

            const formattedData = jsonData.slice(1).map((row, index) => {
                const cleanEmailStr = cleanEmail(row[0]);
                if (!isValidEmail(cleanEmailStr)) {
                    invalidEmails.push(`Ligne ${index + 2}: ${cleanEmailStr}`);
                } else if (emailSet.has(cleanEmailStr)) {
                    duplicates.add(cleanEmailStr);
                } else {
                    emailSet.add(cleanEmailStr);
                }
                return {
                    email: cleanEmailStr,
                    login: row[1],
                };
            }).filter(row => isValidEmail(row.email) && !duplicates.has(row.email));

            if (invalidEmails.length > 0 || duplicates.size > 0) {
                triggerNotification({
                    type: 'error',
                    content: `Des erreurs ont été trouvées :
                    ${invalidEmails.length > 0 ? `\nEmails invalides:\n- ${invalidEmails.join("\n- ")}` : ''}
                    ${duplicates.size > 0 ? `\nEmails en doublons:\n- ${Array.from(duplicates).join("\n- ")}` : ''}`,
                    duration: 3000,
                    persistent: false
                });
                setIsLoading(false);
                return;
            }

            let errorOccurred = false;

            for (let i = 0; i < formattedData.length; i += CHUNK_SIZE) {
                const chunk = formattedData.slice(i, i + CHUNK_SIZE);
                try {
                    const response = await authFetch(`${config.API_URL}/emailing/credentials/add`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(chunk),
                    });
                    if (!response.ok) {
                        const errorBody = await response.json();
                        throw new Error(errorBody.error);
                    }
                } catch (error) {
                    console.error("Erreur lors de l'envoi d'un chunk:", error);
                    triggerNotification({
                        type: 'error',
                        content: `Erreur lors de l'envoi d'un chunk: ${error}`,
                        duration: 3000,
                        persistent: false
                    });
                    setIsLoading(false);
                    errorOccurred = true;
                    break;
                }
            }

            if (!errorOccurred) {
                triggerNotification({
                    type: 'success',
                    content: 'Les identifiants ont été mis à jour avec succès.',
                    duration: 3000,
                    persistent: false
                });
                fetchCredentials(currentPage);
            }
            setIsLoading(false);
        };

        reader.onerror = () => {
            triggerNotification({
                type: 'error',
                content: 'Erreur de lecture du fichier Excel',
                duration: 3000,
                persistent: false
            });
            setIsLoading(false);
        };

        reader.readAsBinaryString(file);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const clearCredentials = async () => {
        setIsLoading(true);
        try {
            const response = await authFetch(`${config.API_URL}/emailing/credentials/delete`, {
                method: 'DELETE',
            });
            if (!response.ok) throw new Error("Erreur lors de la suppression des identifiants");
            triggerNotification({
                type: 'success',
                content: "Tous les identifiants ont été supprimés.",
                duration: 3000,
                persistent: false
            });
            setCredentials([]);
            setCurrentPage(1);
            setTotalPages(0);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    return (
        <div>
            <HelpPopUp instructions={helpInstructions} />
            {isLoading && <Loader />}
            <Breadcrumb />
            <h1>Gestion des identifiants</h1>
            {isLoading && <Loader />}

            <h3>Fournissez un fichier excel xls avec les colonnes Email (A), Login (B)</h3>
            <input type="file" onChange={handleFileChange} />
            <Button onClick={() => handleCredentialsUpload(selectedFile)} disabled={!selectedFile || isLoading}>
                {isLoading ? 'Chargement...' : 'Envoyer le fichier'}
            </Button>
            <Button onClick={clearCredentials}>Vider les identifiants</Button>

            <div className="filter-bar">
                <TextInput
                    placeholder="Filtrer par email"
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)}
                />
            </div>
            <CredentialsList credentials={credentials} />
            {credentials.length > 0 && (
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            )}
        </div>
    );
};

export default Credentials;
