const config = {
    "OPERATION_NAME":"Ake Arkema",
    "API_URL":"https://2024.ake.arkema.api.b-fly.com/",
    "TINY_MCE_API_KEY":"1527x3ef5ajbywfdm25kadjmxzahw16uvrzdkfdn0sydl15y",
    "AUTH0_DOMAIN": "butterfly-henner.eu.auth0.com",
    "AUTH0_CLIENT_ID": "YmTdPRER56APbE3IS1pjFqhlLx1ylfqt",
    "AUTH0_AUDIENCE": "https://2024.ake.arkema.api.b-fly.com",
};

export default config;
